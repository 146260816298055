/* 
 * @Author: 曹俊杰 
 * @Date: 2024-03-14 03:28:58
 * @Module:上传图片表单 
 */
 <template>
  <div>
    <div class="box">
      <div class="imageBox" style="width:80px;height:80px;" v-for="(item,index) in imageList" :key="index">
        <img :src="item" style="width:80px;height:80px;" />
        <div class="mask">
          <i class="el-icon-zoom-in" @click="()=>zoom(item)"></i>
          <i class="el-icon-delete" @click="()=>del(item)"></i>
        </div>
      </div>

      <div class="plus" v-show="imageList.length<limit" @click="uploadDialogShow"><i slot="default" class="el-icon-plus"></i></div>
    </div>
    <uploadDialog ref="uploadDialog" multiple :limit="limit" tip="只能上传jpg/png文件" accept="image/jpg,image/jpeg,image/png" @success="uploadDialogSuccess" />
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>

</template>
 <script>
import uploadDialog from "@/components/uploadDialog"
export default {
  components: {
    uploadDialog
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    limit: {
      type: Number,
      default: 9
    }
  },
  data () {
    return {
      dialogVisible: false,
      dialogImageUrl: '',
    };
  },
  computed: {
    imageList () {
      if (this.value.length)
        return this.value.split(',').map(item => "https://oss.mcn-open.com/" + item)
      else
        return []
    },
  },
  mounted () { },
  methods: {
    zoom (item) {
      this.dialogImageUrl = item;
      this.dialogVisible = true
    },
    del (item) {
      // const url = item.split("https://oss.mcn-open.com/").join("");
      // console.log(url)
      // this.change(this.value.split(url).join(""))
			const url =this.imageList.filter(itm => itm !== item).join(",").split("https://oss.mcn-open.com/").join("")
			this.change(url)
    },
    change (value) {
      this.$emit('input', value)
    },
    uploadDialogShow () {
      this.$refs.uploadDialog.show()
    },
    uploadDialogSuccess (arr) {
      if (this.imageList.length == 0) {
        this.change(arr.toString())
      } else if (this.imageList.length <= this.limit) {
        this.change(this.value.split(',').concat(arr.slice(0, this.limit - this.imageList.length)).toString())
      } else {
        this.$message({
          type: 'error',
          message: `最多上传${this.limit}张图片`
        })
      }
    },
  },
};
 </script>
 <style lang='scss' scoped>
.box {
  display: flex;
  .imageBox {
    margin-right: 5px;
    border-radius: 5px;
    margin-bottom: 5px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    .mask {
      width: 80px;
      height: 80px;
      position: absolute;
      top: 0px;
      left: 0px;
      background: rgba($color: #000000, $alpha: 0.5);
      // background: #ff6;
      z-index: 2;
      color: #fff;
      cursor: pointer;
      font-size: 20px;
      align-items: center;
      justify-content: space-around;
      display: none;
    }
    &:hover {
      .mask {
        display: flex;
      }
    }
    img {
      display: block;
    }
  }
  flex-wrap: wrap;
  .plus {
    width: 80px;
    height: 80px;
    border: 1px solid #dcdfe6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #ccc;
    background: rgba($color: #0d72ff, $alpha: 0.02);
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 5px;
  }
  .plus:hover {
    border: 1px solid rgba($color: #0d72ff, $alpha: 0.4);
    width: 80px;
    height: 80px;
  }
}
</style>