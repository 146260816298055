<template>
  <div>
    <div class="dashboard">
      <div class="title">内部统计</div>
      <el-row :gutter="24">
        <el-col :span="6">
          <statistics title="客户总数量" type="type1" :total="companyDashboard.total" :total1="companyDashboard.total1" :total7="companyDashboard.total7" :total30="companyDashboard.total30" />
        </el-col>
        <el-col :span="6">
          <statistics title="案件总数量" type="type2" :total="lawsuitDashboard.total" :total1="lawsuitDashboard.total1" :total7="lawsuitDashboard.total7" :total30="lawsuitDashboard.total30" />
        </el-col>
        <el-col :span="6">
          <statistics title="与我相关客户" type="type3" :total="companyDashboard.myTotal" :total1="companyDashboard.myTotal1" :total7="companyDashboard.myTotal7" :total30="companyDashboard.myTotal30" />
        </el-col>
        <el-col :span="6">
          <statistics title="与我相关案件" type="type4" :total="lawsuitDashboard.myTotal" :total1="lawsuitDashboard.myTotal1" :total7="lawsuitDashboard.myTotal7" :total30="lawsuitDashboard.myTotal30" />
        </el-col>
      </el-row>

    </div>
    <div class="dashboard" style="margin-top:20px;">
      <div class="title">外部统计</div>
      <el-row :gutter="24">
        <el-col :span="6">
          <statistics title="外部客户总数量" type="type1" :total="outerCompanyDashboard.total" :total1="outerCompanyDashboard.total1" :total7="outerCompanyDashboard.total7" :total30="outerCompanyDashboard.total30" />
        </el-col>
        <el-col :span="6">
          <statistics title="外部案件总数量" type="type2" :total="outerLawsuitDashboard.total" :total1="outerLawsuitDashboard.total1" :total7="outerLawsuitDashboard.total7" :total30="outerLawsuitDashboard.total30" />
        </el-col>
        <el-col :span="6">
          <statistics title="与我相关外部客户" type="type3" :total="outerCompanyDashboard.myTotal" :total1="outerCompanyDashboard.myTotal1" :total7="outerCompanyDashboard.myTotal7" :total30="outerCompanyDashboard.myTotal30" />
        </el-col>
        <el-col :span="6">
          <statistics title="与我相关外部案件" type="type4" :total="outerLawsuitDashboard.myTotal" :total1="outerLawsuitDashboard.myTotal1" :total7="outerLawsuitDashboard.myTotal7" :total30="outerLawsuitDashboard.myTotal30" />
        </el-col>
      </el-row>
    </div>

    <el-row :gutter="24" style="margin-top:20px">
      <el-col :span="18">
        <ranking :companyDashboard="companyDashboard" :lawsuitDashboard="lawsuitDashboard" />
      </el-col>
      <el-col :span="6">
        <div>
          <div class="searching">
            <div class="title">客户检索</div>
            <div class="content" @click="()=>$router.push('/customerSearch')">
              <el-input placeholder="请输入公司全称（全国范围检索）" size="small" :disabled="true">
              </el-input>
            </div>
          </div>
          <DynamicStateList style="margin-top:20px;" :list="trendList" />
        </div>
      </el-col>
    </el-row>

  </div>
</template>
<script>
import DynamicStateList from "@/components/DynamicStateList"
import statistics from "@/views/Home/components/statistics"
import ranking from "@/views/Home/components/ranking"
export
  default {
    components: {
      DynamicStateList,
      statistics,
      ranking
    },
    data () {
      return {
        trendList: [],
        tableData: [{
          name: '客户数量',
          zs: 100,
          ssr: 50,
          qr: 30,
          zr: 20
        }, {
          name: '案件数量',
          zs: 100,
          ssr: 50,
          qr: 30,
          zr: 20
        }],
        lawsuitDashboard: {
          incrementTop10: [],
          myTotal: 0,
          myTotal1: 0,
          myTotal7: 0,
          myTotal30: 0,
          total: 0,
          total1: 0,
          total7: 0,
          total30: 0,
          totalTop10: []
        },
        outerLawsuitDashboard: {
          incrementTop10: [],
          myTotal: 0,
          myTotal1: 0,
          myTotal7: 0,
          myTotal30: 0,
          total: 0,
          total1: 0,
          total7: 0,
          total30: 0,
          totalTop10: []
        },
        companyDashboard: {
          incrementTop10: [],
          myTotal: 0,
          myTotal1: 0,
          myTotal7: 0,
          myTotal30: 0,
          total: 0,
          total1: 0,
          total7: 0,
          total30: 0,
          totalTop10: []
        },
        outerCompanyDashboard: {
          incrementTop10: [],
          myTotal: 0,
          myTotal1: 0,
          myTotal7: 0,
          myTotal30: 0,
          total: 0,
          total1: 0,
          total7: 0,
          total30: 0,
          totalTop10: []
        }
      };
    },
    mounted () {
      this.getCompanyDashboard();
      this.getOuterCompanyDashboard();
      this.getLawsuitDashboard();
      this.getOuterLawsuitDashboard();
      this.getTrendList();
    },
    methods: {
      async getTrendList () {
        const { status, data } = await this.$api.getTrendList({ companyUuid: this.$route.params.uuid });
        if (status === 200) {
          this.trendList = data.records;
        }
      },
      async getCompanyDashboard () {
        const { status, data } = await this.$api.getCompanyDashboard();
        if (status === 200) {
          this.companyDashboard = data;
        }
      },
      async getOuterCompanyDashboard () {
        const { status, data } = await this.$api.getOuterCompanyDashboard();
        if (status === 200) {
          this.outerCompanyDashboard = data;
        }
      },
      async getLawsuitDashboard () {
        const { status, data } = await this.$api.getLawsuitDashboard();
        if (status === 200) {
          this.lawsuitDashboard = data;
        }
      },
      async getOuterLawsuitDashboard () {
        const { status, data } = await this.$api.getOuterLawsuitDashboard();
        if (status === 200) {
          this.outerLawsuitDashboard = data;
        }
      }
    },
  };
</script>
<style lang='scss' scoped>
.dashboard {
  background: #fff;
  padding: 0 16px 16px;
	border-radius: 4px;
  .title {
    line-height: 56px;
    font-weight: 400;
    font-size: 16px;
  }
}
.searching {
  height: 108px;
  background: #ffffff;
  border-radius: 4px;
  .title {
    line-height: 56px;
    padding: 0 16px;
    font-weight: 400;
    font-size: 16px;
  }
  .content {
    padding: 0 16px;
  }
  & /deep/ .is-disabled {
    cursor: pointer !important;
    .el-input__inner {
      cursor: pointer !important;
    }
  }
}
</style>