var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":_vm.type=='add'?'添加账号':_vm.type=='revision'?'修改账号':'',"visible":_vm.dialogVisible,"width":"800px"},on:{"update:visible":function($event){_vm.dialogVisible=$event},"closed":_vm.closed}},[_c('div',{staticStyle:{"padding":"20px"}},[_c('el-form',{ref:"form",attrs:{"size":"mini","label-position":"left","rules":_vm.rules,"label-width":"80px","model":_vm.form}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"手机号","prop":"phone"}},[_c('el-input',{attrs:{"placeholder":"手机号用于登录"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('el-form-item',{attrs:{"label":"用户名","prop":"name"}},[_c('el-input',{attrs:{"placeholder":"请输入用户名"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),(_vm.form.role =='PARTNER')?_c('el-form-item',{attrs:{"label":"等级","prop":"level","rules":{ required: true, message: '等级不能为空'}}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择等级"},model:{value:(_vm.form.level),callback:function ($$v) {_vm.$set(_vm.form, "level", $$v)},expression:"form.level"}},_vm._l(([
							{
								label:'分公司',
								value:2
							},
							{
								label:'省级代理',
								value:3
							},
							{
								label:'市级代理',
								value:4
							}]),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1):_vm._e(),(_vm.form.role =='LEGAL')?_c('el-form-item',{attrs:{"label":"法务团队","prop":"teamId","rules":{ required: true, message: '法务团队不能为空'}}},[_c('team',{model:{value:(_vm.form.teamId),callback:function ($$v) {_vm.$set(_vm.form, "teamId", $$v)},expression:"form.teamId"}})],1):_vm._e(),_c('el-form-item',{attrs:{"label":"角色","prop":"role"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择角色"},model:{value:(_vm.form.role),callback:function ($$v) {_vm.$set(_vm.form, "role", $$v)},expression:"form.role"}},_vm._l(([
							{
								label:'合作伙伴',
								value:'PARTNER'
							},
							{
								label:'法务',
								value:'LEGAL'
							},
							{
								label:'管理员',
								value:'ADMIN'
							},
							{
								label:'超级管理员',
								value:'SUPER'
							},
							{
								label:'联盟会员',
								value:'PROMOTER'
							},
							{
								label:'外部管理员',
								value:'OUTER'
							},{
								label:'律师',
								value:'LAWYER'
							}]),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"联系人","prop":"contact"}},[_c('el-input',{attrs:{"placeholder":"请输入联系人"},model:{value:(_vm.form.contact),callback:function ($$v) {_vm.$set(_vm.form, "contact", $$v)},expression:"form.contact"}})],1),_c('el-form-item',{attrs:{"label":"联系电话","prop":"mobile"}},[_c('el-input',{attrs:{"placeholder":"请输入联系电话"},model:{value:(_vm.form.mobile),callback:function ($$v) {_vm.$set(_vm.form, "mobile", $$v)},expression:"form.mobile"}})],1),_c('el-form-item',{attrs:{"label":"微信号","prop":"wx"}},[_c('el-input',{attrs:{"placeholder":"请输入微信号"},model:{value:(_vm.form.wx),callback:function ($$v) {_vm.$set(_vm.form, "wx", $$v)},expression:"form.wx"}})],1),_c('el-form-item',{attrs:{"label":"常驻地","prop":"address"}},[_c('el-input',{attrs:{"placeholder":"请输入常驻地"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})],1),_c('el-form-item',{attrs:{"label":"生日","prop":"birthday"}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"value-format":"timestamp","type":"date","placeholder":"选择生日"},model:{value:(_vm.form.birthday),callback:function ($$v) {_vm.$set(_vm.form, "birthday", $$v)},expression:"form.birthday"}})],1)],1)],1)],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.submit}},[_vm._v("确 定")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }