/* 
 * @Author: 曹俊杰 
 * @Date: 2023-08-18 11:07:04
 * @Module: 导出列表
 */
 <template>
  <div>
    <el-badge :is-dot="badge" class="pointer exportdata" style="margin-right:30px;">
      <el-button class="share-button" icon="el-icon-share" type="text" size="mini" @click="getExportList">导出列表</el-button>
    </el-badge>
    <el-drawer title="导出列表" :visible.sync="drawer" direction="rtl" :before-close="handleClose">
      <div style="padding:10px">
        <el-table :data="tableData" size="mini">
          <el-table-column fixed prop="name" label="名称" width="180">
            <template slot-scope="scope">
              <el-button type="text" size="mini" :disabled="scope.row.state==0" @click="()=>down(scope.row)">{{scope.row.name}}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="类型">
            <template slot-scope="scope">
              <span v-if="scope.row.type==0" style="color:#409EFF">内部案件</span>
              <span v-else-if="scope.row.type==1" style="color:#67C23A">外部案件</span>
              <span v-else-if="scope.row.type==2" style="color:#E6A23C">内部客户</span>
              <span v-else-if="scope.row.type==3" style="color:#F56C6C">外部客户</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="state" label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.state==0" style="color:#E6A23C">生成中</span>
              <span v-else-if="scope.row.state==1" class="el-icon-success" style="color:#67C23A"></span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="createdAt" label="创建时间" width="90">
            <template slot-scope="scope">
              <span v-if="scope.row.createdAt">{{scope.row.createdAt|timeFormat}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </el-drawer>
  </div>
</template>
 <script>
import { mapState } from "vuex";
export default {
  components: {},
  data () {
    return {
      drawer: false,
      loading: false,
      tableData: []
    };
  },
  computed: {
    ...mapState({
      badge: state => state.exportData.badge,
    }),
  },
  mounted () { },
  methods: {
    visibleChange (res) {
      console.log(res)
    },
    command (res) {
      console.log(res)
    },
    handleClose () {
      this.drawer = false
    },
    async getExportList () {
      this.loading = true
      const { status, data, info } = await this.$api.getExportList();
      if (status == 200) {
        this.drawer = true;
        this.tableData = data;
        this.$store.commit("exportData/setBadge", false);
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
      this.loading = false
    },
    down (res) {
      window.open(this.$oss + res.fileUrl)
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>