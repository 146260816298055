import Router from '@/router'
const state = {
	user: {
		createdAt: '',
		name: "",
		phone: "",
		role: "",
		token: "",
		updatedAt: '',
		uuid: ""
	}
}

const mutations = {
	setUserInfo (state, payload) {
		state.user = {
			...state.user,
			...payload
		};
		// state.commit("userLog/saveUserLog", state.user)
	},
	logout (state) {
		state.user = {
			createdAt: '',
			name: "",
			phone: "",
			role: "",
			token: "",
			updatedAt: '',
			uuid: ""
		}
		Router.push({
			path: '/login', query: { redirect: Router.path }
		})
		window.clearVuexAlong();
	}

}
const actions = {
	setUserInfo ({ commit, state, dispatch }, params) {
		commit('setUserInfo', params);
	}
}

export default {
	namespaced: true,
	state,
	actions,
	mutations
}