/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-11 19:11:10
 * @Module: 区域
 */
 <template>
  <el-cascader filterable style="width:100%;" :props="{emitPath:false}" :show-all-levels="false" :value="value" @change="change" :options="regionAllList" clearable>
  </el-cascader>
</template>
 
<script>
import area from "@/static/json/area.js"
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      regionAllList: area,
      list: [{
        value: 'zhinan',
        label: '指南',
        children: [{
          value: 'shejiyuanze',
          label: '设计原则'

        }, {
          value: 'yizhi',
          label: '一致'
        }, {
          value: 'fankui',
          label: '反馈'
        }, {
          value: 'xiaolv',
          label: '效率'
        }, {
          value: 'kekong',
          label: '可控'
        }]
      }]
    }
  },
  mounted () {

  },
  methods: {
    change (value) {
      console.log(value)
      this.$emit("input", value)
    }
  }
}
</script>