/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-11 11:45:50
 * @Module: 账号管理表单
 */
 <template>

  <el-dialog :title="type=='add'?'添加账号':type=='revision'?'修改账号':''" :visible.sync="dialogVisible" width="800px" @closed="closed">
    <div style="padding:20px">
      <el-form size="mini" label-position="left" :rules="rules" label-width="80px" :model="form" ref="form">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="form.phone" placeholder="手机号用于登录"></el-input>
            </el-form-item>
            <el-form-item label="用户名" prop="name">
              <el-input v-model="form.name" placeholder="请输入用户名"></el-input>
            </el-form-item>
            <!-- <el-form-item label="密码" prop="pwd">
            <el-input v-model="form.pwd" placeholder="请输入密码"></el-input>
          </el-form-item> -->
            <el-form-item label="等级" prop="level" v-if="form.role =='PARTNER'" :rules="{ required: true, message: '等级不能为空'}">
              <el-select v-model="form.level" style="width:100%;" placeholder="请选择等级">
                <el-option v-for="item in [
							{
								label:'分公司',
								value:2
							},
							{
								label:'省级代理',
								value:3
							},
							{
								label:'市级代理',
								value:4
							}]" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
						 <el-form-item label="法务团队" prop="teamId" v-if="form.role =='LEGAL'" :rules="{ required: true, message: '法务团队不能为空'}">
              <team v-model="form.teamId"/>
            </el-form-item>
            <el-form-item label="角色" prop="role">
              <el-select v-model="form.role" style="width:100%;" placeholder="请选择角色">
                <el-option v-for="item in [
							{
								label:'合作伙伴',
								value:'PARTNER'
							},
							{
								label:'法务',
								value:'LEGAL'
							},
							{
								label:'管理员',
								value:'ADMIN'
							},
							{
								label:'超级管理员',
								value:'SUPER'
							},
							{
								label:'联盟会员',
								value:'PROMOTER'
							},
							{
								label:'外部管理员',
								value:'OUTER'
							},{
								label:'律师',
								value:'LAWYER'
							}]" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <!-- <el-radio-group v-model="form.role">
                <el-radio label="PARTNER">合作伙伴</el-radio>
                <el-radio label="LEGAL">法务</el-radio>
                <el-radio label="ADMIN">管理员</el-radio>
                <el-radio label="OUTER">外部管理员</el-radio>
              </el-radio-group> -->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人" prop="contact">
              <el-input v-model="form.contact" placeholder="请输入联系人"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="mobile">
              <el-input v-model="form.mobile" placeholder="请输入联系电话"></el-input>
            </el-form-item>
            <el-form-item label="微信号" prop="wx">
              <el-input v-model="form.wx" placeholder="请输入微信号"></el-input>
            </el-form-item>
            <el-form-item label="常驻地" prop="address">
              <el-input v-model="form.address" placeholder="请输入常驻地"></el-input>
            </el-form-item>
            <el-form-item label="生日" prop="birthday">
              <el-date-picker value-format="timestamp" style="width:100%;" v-model="form.birthday" type="date" placeholder="选择生日" />
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
      <el-button type="primary" @click="submit" size="mini">确 定</el-button>
    </span>
  </el-dialog>
</template>
 <script>
import team from "@/views/AccountManagement/components/form/team"
export default {
  components: {
    team
  },
  data () {
    const phoneValidator = (_, value, callback) => {
      if (this.$utils.test.mobile(value)) {
        return callback();
      } else {
        return callback(new Error('请输入正确的手机号'));
      }
    }
    const pwdValidator = (_, value, callback) => {
      if (this.type == 'revision') {
        return callback();
      } else if (value) {
        return callback();
      } else {
        return callback(new Error('请输入正确的密码'));
      }
    }
    return {
      type: 'add',
      dialogVisible: false,
      form: {
        phone: '',
        name: '',
        teamId: '',
        role: 'PARTNER',
        contact: '',
        mobile: '',
        wx: '',
        address: '',
        birthday: '',
        level: ''
      },
      //rules前端验证
      rules: {
        phone: [{ required: true, message: '手机号不能为空', trigger: 'blur' },
        { validator: phoneValidator, trigger: 'blur' }],
        // pwd: [
        //   { validator: pwdValidator, trigger: 'blur' },
        // ],
        role: [
          { required: true, message: '角色不能为空', trigger: 'blur' },
        ],
        name: [
          { required: true, message: '用户名不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  mounted () { },
  methods: {
    show ({ type = 'add', data }) {
      if (type == "revision") {
        this.form = data
      }
      this.type = type
      this.dialogVisible = true
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.putAccount()
        } else {
          return false
        }
      })
    },
    closed () {
      this.form = {
        phone: '',
        name: '',
        teamId: '',
        role: 'PARTNER',
        contact: '',
        mobile: '',
        wx: '',
        address: '',
        birthday: '',
        level: ''
      }
    },
    async putAccount () {
      const api = {
        revision: this.$api.putChangeAccount,
        add: this.$api.putAccount
      }
      const { info, status } = await api[this.type](this.form);
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.$emit('success', this.type)
        this.dialogVisible = false;
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    },
  },
};
 </script>
 <style lang='scss' scoped>
</style>