<template>
  <div class="menu">
    <p class="title">管理系统</p>
    <div class="menu-content">
      <template v-for="item in menuList">
        <div class="menu-content-item pointer" v-if="(item.authority=='all')||( item.authority.indexOf(user.role) != -1)" @click="$router.push({path:item.name})" :class="defaultActive==item.name?('active ' +item.class):item.class" :key="item.name">
          <i :class="defaultActive==item.name?item.activeIcon:item.icon"></i>
          <div>{{item.title}}</div>
        </div>
      </template>
    </div>
    <!-- <el-menu router :default-active="defaultActive" :collapse="collapse">
      <template v-for="item in menuList">
        <el-menu-item :index="item.name" :key="item.name" v-if="(item.authority=='all')||( item.authority.indexOf(user.role) != -1)">
          <i :class="item.icon"></i>
          <span slot="title" style="font-size:12px;">
            {{item.title}}
          </span>
        </el-menu-item>
      </template>
    </el-menu> -->
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  components: {},
  data () {
    // 		角色
    //    PARTNER-合作伙伴
    //    LEGAL-法务
    //    ADMIN-管理员
    //    OUTER-外部管理员
    //    SUPER-超级管理员
    return {
      menuList: [
        {
          title: '首页',
          name: '/home',
          class: 'home',
          icon: 'el-icon-ali-a-shouye2',
          activeIcon: 'el-icon-ali-shouye',
          authority: 'PARTNER,LEGAL,ADMIN,SUPER'
        },
        {
          title: '内部客户',
          name: '/customerManagement',
          class: 'customerManagement',
          icon: 'el-icon-ali-kehuguanli2',
          activeIcon: 'el-icon-ali-kehuguanli',
          authority: 'PARTNER,LEGAL,ADMIN,SUPER'
        },

        {
          title: '分账明细',
          name: '/earnings',
          class: 'earnings',
          icon: 'el-icon-ali-fenzhangmingxi2',
          activeIcon: 'el-icon-ali-fenzhangmingxi',
          authority: 'PARTNER,LEGAL,ADMIN,SUPER'
        },
        // {
        //   title: '客户检索',
        //   name: '/customerSearch',
        //   class: 'customerSearch',
        //   icon: 'el-icon-ali-kehujiansuo2',
        //   activeIcon: 'el-icon-ali-kehujiansuo',
        //   authority: 'PARTNER,LEGAL,ADMIN,SUPER'
        // },
        {
          title: '辖区联络',
          name: '/contacts',
          class: 'contacts',
          icon: 'el-icon-ali-kehujiansuo2',
          activeIcon: 'el-icon-ali-shouye',
          authority: 'PARTNER,LEGAL'
        },
        {
          title: '内部案件',
          name: '/caseManagement',
          class: 'caseManagement',
          icon: 'el-icon-ali-anjianguanli2',
          activeIcon: 'el-icon-ali-anjianguanli',
          authority: 'PARTNER,LEGAL,ADMIN,SUPER,LAWYER'
        },

        {
          title: '个案管理',
          name: '/icase',
          class: 'icase',
          icon: 'el-icon-ali-geanguanli2',
          activeIcon: 'el-icon-ali-geanguanli',
          authority: 'ADMIN,OUTER,SUPER'
        },
        {
          title: '账号管理',
          name: '/accountManagement',
          class: 'accountManagement',
          icon: 'el-icon-ali-zhanghaoguanli2',
          activeIcon: 'el-icon-ali-zhanghaoguanli',
          authority: 'SUPER'
        },
        {
          title: '外部客户',
          name: '/outerCustomerManagement',
          class: 'outerCustomerManagement',
          icon: 'el-icon-ali-kehuguanli2',
          activeIcon: 'el-icon-ali-kehuguanli',
          authority: 'ADMIN,OUTER,SUPER,LEGAL'
        },
        {
          title: '外部案件',
          name: '/outerCaseManagement',
          class: 'outerCaseManagement',
          icon: 'el-icon-ali-anjianguanli2',
          activeIcon: 'el-icon-ali-anjianguanli',
          authority: 'ADMIN,OUTER,SUPER,LAWYER,LEGAL'
        },
				 {
          title: '意向客户',
          name: '/customerIntended',
          class: 'customerIntended',
          icon: 'el-icon-ali-kehuguanli2',
          activeIcon: 'el-icon-ali-kehuguanli',
          authority: 'PARTNER,LEGAL,ADMIN,SUPER'
        },
        {
          title: '联盟会员',
          name: '/promoter',
          class: 'promoter',
          icon: 'el-icon-ali-huiyuan',
          activeIcon: 'el-icon-ali-huiyuan1',
          authority: 'ADMIN,PROMOTER,SUPER'
        },
       
        {
          title: '成单记录',
          name: '/deal',
          class: 'deal',
          icon: 'el-icon-ali-shijian',
          activeIcon: 'el-icon-ali-shijian1',
          authority: 'ADMIN,PROMOTER,SUPER'
        },
        {
          title: '佣金明细',
          name: '/commission',
          class: 'commission',
          icon: 'el-icon-ali-dianyingpiao',
          activeIcon: 'el-icon-ali-dianyingpiao1',
          authority: 'ADMIN,PROMOTER,SUPER'
        },
      ],
      defaultActive: '/home'
    };
  },
  computed: {
    ...mapState({
      user: state => state.userInfo.user,
      collapse: state => state.collapse
    })
  },
  mounted () { },
  methods: {},
  watch: {
    "$route.meta": {
      handler ({ menu }) {
        this.defaultActive = '/' + menu
      },
      immediate: true
    }
  }
};
</script>
<style lang='scss' scoped>
.active {
  background: #051752;
  opacity: 1 !important;
  border-radius: 4px;
}
.menu {
  background: #12245c;
  width: 96px;
  height: 100%;
  position: relative;
  .title {
    width: 96px;
    height: 60px;
    color: #ffffff;
    font-size: 12px;
    position: fixed;
  }
  &-content {
    overflow-y: auto;
    height: calc(100% - 60px);
    &::-webkit-scrollbar {
      width: 0 !important;
    }
    width: inherit;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    color: #fff;
    top: 60px;

    &-item {
      flex-shrink: 0;
      width: 80px;
      height: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      opacity: 0.8;
      line-height: 21px;
      i {
        margin-bottom: 4px;
      }
      &:hover {
        @extend .active;
      }
    }
    // .outerCustomerManagement,
    // .outerCaseManagement {
    //   color: #f8a3a3;
    // }
  }
}
.el-menu {
  border-right-width: 0;
}
</style>