/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-23 13:41:15
 * @Module: 筛选表单
 */
 
 <template>
  <el-form :inline="true" :model="searchForm" size="mini" style="margin-top:20px;">
    <el-form-item label="关键词">
      <el-input v-model="searchForm.keyword" @keyup.enter.native="onSubmit" style="width:300px;" placeholder="请输入要搜索的用户名、联系人、联系电话" clearable></el-input>
    </el-form-item>
    <el-form-item label="角色">
      <el-select v-model="searchForm.role" style="width:100%;" placeholder="请选择角色" @keyup.enter.native="onSubmit" clearable>
        <el-option v-for="item in [
							{
								label:'合作伙伴',
								value:'PARTNER'
							},
							{
								label:'法务',
								value:'LEGAL'
							},
							{
								label:'管理员',
								value:'ADMIN'
							},
							{
								label:'超级管理员',
								value:'SUPER'
							},
							{
								label:'联盟会员',
								value:'PROMOTER'
							},
							{
								label:'外部管理员',
								value:'OUTER'
							},{
								label:'律师',
								value:'LAWYER'
							}]" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="法务团队">
      <team v-model="searchForm.teamId" @onSubmit="onSubmit" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">查询</el-button>
    </el-form-item>
  </el-form>

</template>
 <script>
import team from "@/views/AccountManagement/components/form/team"
export default {
  components: {
    team
  },
  props: {
    searchForm: {
      type: Object,
      default: () => ({
        keyword: '',
        role: '',
        teamId: ''
      })
    }
  },
  data () {
    return {

    };
  },
  mounted () {

  },
  methods: {
    onSubmit () {
      this.$emit('submit')
    },


  },
};
 </script>
 <style lang='scss' scoped>
</style>