/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-06 13:37:09
 * @Module: 内部客户
 */
 <template>
  <div>
    <div style="mine-height:68px;background:#fff;dispaly:flex; align-items: center;	padding: 0 16px;overflow:hidden;">
      <searchForm :searchForm.sync="listParams" @submit="getCompanyList" />
    </div>
    <div style="padding:16px;background:#fff;margin-top:24px;border-radius: 4px;padding-top:0px;">
      <div style="display: flex;	justify-content: space-between;	align-items: center;height:56px;">
        <div>客户列表</div>

        <span >
          <el-button-group >
            <el-button type="primary" v-show="['ADMIN','SUPER'].includes(user.role)" size="small" icon="el-icon-share" @click="exportData">导出</el-button>
            <el-button type="primary" v-show="['ADMIN','SUPER','LEGAL'].includes(user.role)" size="small" icon="el-icon-plus" @click="add">添加客户</el-button>
            <el-button type="primary" size="small" icon="el-icon-search" @click="$router.push({path:'/customerSearch'})">客户检索</el-button>
          </el-button-group>
        </span>
      </div>
      <el-table size="mini" @sort-change="sortChange" :data="list" tooltip-effect="dark" style="width: 100%;margin-top:10px;">
        <el-table-column label="公司全称" show-overflow-tooltip width="120" prop="name">
          <template slot-scope="scope">
            <span class="pointer primary" @click="$router.push({path:'/customerManagement/'+scope.row.uuid})">{{ scope.row.name||"--" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="经营地" prop="areaName" show-overflow-tooltip width="90">
          <template slot-scope="scope">{{ scope.row.areaName||"--" }}</template>
        </el-table-column>
        <el-table-column label="主营业务" prop="businesses" show-overflow-tooltip min-width="70">
          <template slot-scope="scope">
            <template v-for="(item,index) in scope.row.businesses">
              <CTag size="mini" :key="index" :type="item.businessId%5" effect="plain">
                {{item.name}}
              </CTag>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="流水" prop="scale" sortable="custom" show-overflow-tooltip width="100">
          <template slot-scope="scope">{{ scope.row.scale ||"--"}}</template>
        </el-table-column>
        <el-table-column label="法务" prop="legalName" show-overflow-tooltip width="60">
          <template slot-scope="scope">{{ scope.row.legalName ||"--"}}</template>
        </el-table-column>
        <el-table-column label="实收服务费" prop="received" sortable="custom" show-overflow-tooltip width="110">
          <template slot-scope="scope">
            <span class="primary">¥{{ scope.row.received }}</span>
          </template>
        </el-table-column>
        <el-table-column label="谁签的" prop="signerName" show-overflow-tooltip width="140">
          <template slot-scope="scope">{{ scope.row.signerName ||"--"}}</template>
        </el-table-column>
        <el-table-column label="合同状态" prop="signState" show-overflow-tooltip width="70">
          <template slot-scope="scope">
            <div v-if="scope.row.signState=='UNSIGNED'" style="display:flex;align-items:center">
              <i class="el-icon-ali-dian1" style="color:#ccc;"></i>
              <span style="color:#666;">无合同</span>
            </div>
            <div v-else-if="scope.row.signState=='NORMAL'" style="display:flex;align-items:center">
              <i class="el-icon-ali-dian1" style="color:#0AC258;"></i>
              <span style="color:#262626;">正常</span>
            </div>
            <div v-else-if="scope.row.signState=='AAA'" style="display:flex;align-items:center">
              <i class="el-icon-ali-dian1" style="color:#0d72ff;"></i>
              <span style="color:#262626;">AAA</span>
            </div>
            <div v-else-if="scope.row.signState=='CANCELLED'" style="display:flex;align-items:center">
              <i class="el-icon-ali-dian1" style="color:#FF0D0D;"></i>
              <span style="color:#262626;">解除</span>
            </div>
            <div v-else-if="scope.row.signState=='EXPIRED'" style="display:flex;align-items:center">
              <i class="el-icon-ali-dian1" style="color:#E6A23C;"></i>
              <span style="color:#262626;">到期</span>
            </div>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="签约时间" prop="signedAt" sortable="custom" show-overflow-tooltip width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.signedAt">{{ scope.row.signedAt|timeFormat }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="税咨" prop="tax" show-overflow-tooltip width="60">
          <template slot-scope="scope">
            <el-switch :value="scope.row.tax" active-color="#0AC258"  @click.native="()=>taxChange(scope.row)">
            </el-switch>
            <!-- <span v-if="scope.row.tax" style="font-size:20px;color:#0AC258" class="el-icon-success"></span>
            <span v-else class="el-icon-warning" style="font-size:20px;color:#FAA423"></span> -->
          </template>
        </el-table-column>
        <el-table-column label="分账明细" prop="partners" show-overflow-tooltip width="160">
          <template slot-scope="scope">
            <el-popover placement="top" v-if="scope.row.partners.length" trigger="hover">
              <div v-for="item in scope.row.partners" :key="item.uuid" style="line-height:24px;">
                <p>{{item.accountName }} - <span class="primary">{{item.ratio}}%</span> </p>
              </div>
              <p slot="reference" class="pointer">{{scope.row.partners[0].accountName}}- <span class="primary">{{scope.row.partners[0].ratio}}%</span><span v-if="scope.row.partners.length>1">...</span></p>
            </el-popover>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="录入时间" prop="createdAt" sortable="custom" show-overflow-tooltip width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.createdAt">{{ scope.row.createdAt|timeFormat }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="案件数量" prop="caseCount" sortable="custom" show-overflow-tooltip width="100">
          <template slot-scope="scope">
            <span class="primary pointer" v-if="scope.row.caseCount" @click="$router.push({path:'/caseManagement',query: { name: scope.row.name }})">{{scope.row.caseCount}}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="预存余额" sortable="balance" prop="balance" show-overflow-tooltip width="120">
          <template slot-scope="scope">
            <span class="primary" v-if="scope.row.balance">¥{{ scope.row.balance  }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="110" :resizable="false" v-if="['ADMIN','SUPER','LEGAL'].includes(user.role)">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="()=>revision(scope.row)">编辑</el-button>
            <el-button type="text" size="mini" @click="()=>deleteData(scope.row)" v-if="['ADMIN','SUPER'].includes(user.role)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <c-pagination ref="pagination" layout="prev, pager, next,sizes" @change="getCompanyList" />
    </div>

    <add ref='add' @success="getCompanyList" />
  </div>
</template>
 <script>
import CTag from "@/components/CTag"
import add from "@/views/CustomerManagement/components/add"
import searchForm from "@/views/CustomerManagement/components/searchForm"
import { mapState } from "vuex";
export default {
  components: {
    CTag,
    add,
    searchForm
  },
  data () {
    return {
      list: [],
      listParams: {
        keyword: '',
        areaCode: '',
        signState: '',
        legalUuid: '',
        signerUuid: '',
        accountUuid: '',
        orderBy: '',
        order: ''
      }
    };
  },
  computed: {
    ...mapState({
      user: state => state.userInfo.user
    })
  },
  mounted () {
    this.getCompanyList()
  },
  methods: {
    sortChange ({ order, prop }) {
      if (order) {
        this.listParams.order = order;
        this.listParams.orderBy = prop
      } else {
        this.listParams.order = '';
        this.listParams.orderBy = ''
      }
      this.getCompanyList()
    },
    async getCompanyList () {
      const { current, size } = this.$refs.pagination
      const { listParams } = this
      const { status, data } = await this.$api.getCompanyList({ current, size, ...listParams })
      if (status == 200) {
        this.list = data.records;
        this.$refs.pagination.createPagination(data)
      }
    },
    add () {
      this.$refs.add.show({ type: 'add' })
    },
    revision (data) {
      console.log(data)
      const platformIds = data.platforms.map(item => item.platformId)
      const businessIds = data.businesses.map(item => item.businessId)
      this.$refs.add.show({ data: { ...data, platformIds, businessIds }, type: 'revision' })
    },
    async exportData () {
      const { status, info } = await this.$api.postExportCompany()
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.$store.commit("exportData/setBadge", true)
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    },
    async taxChange (res) {
      const { uuid,tax } = res
      const { status, info } = await this.$api.putChangeCompany({
        uuid,
        tax: !tax
      })
      if (status === 200) {
        this.$message({
          type: "success",
          message: info
        })
				this.getCompanyList()
      } else {
        this.$message({
          type: "error",
          message: info
        })
      }
    },
    async deleteData ({ name, uuid }) {
      try {
        await this.$confirm(`此操作将永久删除公司《${name}》, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        const { status, info } = await this.$api.deleteCompany({ uuid })
        if (status === 200) {
          this.$message({
            type: 'success',
            message: info
          })
          this.getCompanyList()
        } else {
          this.$message({
            type: 'error',
            message: info
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>