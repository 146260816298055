/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-12 11:39:12
 * @Module: 团队
 */
 <template>
  <el-select style="width:100%;" placeholder="请选择法务团队" :value="value" @keyup.enter.native="onSubmit" clearable @change="change">
    <el-option v-for="item in teamList" :key="item.teamId" :label="item.name" :value="item.teamId">
    </el-option>
  </el-select>
</template>
 <script>
export default {
  components: {},
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      teamList: []
    };
  },

  mounted () {
    this.getTeamList()
  },
  methods: {
    async getTeamList () {
      const { status, data } = await this.$api.getTeamList()
      if (status == 200) {
        console.log(data)
        this.teamList = data;
      }
    },
    change (value) {
      this.$emit('input', value)
			console.log(321)
    },
		onSubmit(){
			this.$$emit('onSubmit')
		}
  },
};
 </script>
 <style lang='scss' scoped>
</style>